import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PricePipe } from './price.pipe';
import { TotalPricePipe } from './total-price.pipe';


@NgModule({
    declarations: [
        PricePipe,
        TotalPricePipe
    ],
    imports: [
    ],
    exports: [
        PricePipe,
        TotalPricePipe
    ],
})
export class PipesModule {}
